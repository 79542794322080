export default [
  {
    path: '/login',
    redirect: '/',
    meta: {
      requiresAuth: false,
      permission: false,
      layout: 'blank',
      title: 'Login'
    }
  },
  {
    path: '/',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '@/views/login/Login.vue'),
    meta: {
      requiresAuth: false,
      permission: false,
      layout: 'blank',
      title: 'Login'
    }
  }
];
