export default [
  {
    path: '/payment-methods',
    name: 'payment-methods',
    meta: {
      requiresAuth: true,
      permission: 'payment-methods',
      title: 'Métodos de pago',
      module: 'payment-methods',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-account-credit-card'
    },
    component: () =>
      import(
        /* webpackChunkName: "payment-methods" */ '@/views/payment-methods/PaymentMethodLayout.vue'
      ),
    children: [
      {
        path: '',
        name: 'payment-methods-index',
        meta: {
          adminLayoutNav: true,
          permission: 'RideryOffice_Payment_Methods/list',
          requiresAuth: true,
          layout: 'admin',
          title: 'General',
          module: 'payment-methods',
          icon: 'mdi-view-list'
        },
        component: () =>
          import(
            /* webpackChunkName: "payment-methods" */ '@/views/payment-methods/PaymentMethodIndex.vue'
          )
      },
      {
        path: 'setting-by-city',
        name: 'payment-methods-setting-by-city',
        meta: {
          adminLayoutNav: true,
          permission: 'RideryOffice_Payment_Methods/setting',
          requiresAuth: true,
          layout: 'admin',
          title: 'Configuración por ciudad',
          module: 'payment-methods',
          icon: 'mdi-book-cog'
        },
        component: () =>
          import(
            /* webpackChunkName: "payment-methods-setting-by-city" */ '@/views/payment-methods/PaymentMethodSettingByCity.vue'
          )
      },
      {
        path: 'new',
        name: 'payment-methods-new',
        meta: {
          adminLayoutNav: false,
          permission: 'RideryOffice_Payment_Methods/new',
          requiresAuth: true,
          layout: 'admin',
          title: 'Nuevo método de pago',
          module: 'payment-methods',
          icon: 'mdi-plus'
        },
        component: () =>
          import(
            /* webpackChunkName: "payment-methods-new" */ '@/views/payment-methods/PaymentMethodNewShow.vue'
          )
      },
      {
        path: ':id',
        name: 'payment-methods-show',
        meta: {
          requiresAuth: true,
          permission: 'RideryOffice_Payment_Methods/update',
          layout: 'admin',
          title: 'Detalle de método de pago',
          module: 'payment-methods',
          adminLayoutNav: false
        },
        component: () =>
          import(
            /* webpackChunkName: "payment-methods-show" */ '@/views/payment-methods/PaymentMethodNewShow.vue'
          )
      }
    ]
  }
];
