/**
 * Formats a number with dot (".") as thousands separators.
 * This function converts the provided numeric value to a string,
 * adding dot separators for the thousands places without including any decimal part.
 * If the value passed is not a number, it logs an error and returns the input unchanged.
 *
 * @param {number} value - The number to be formatted.
 * @returns {string} The formatted number as a string with thousands separators.
 * If the input is not a number, the original input is returned.
 *
 * @example
//  * // returns '1.222.333'
 * numberFormatNoDecimal(1222333);
 */
const numberFormatNoDecimal = (value) => {
  if (value !== 0 && !Boolean(value)) {
    return '';
  }
  if (typeof value !== 'number') {
    console.error('The value is not a number');
    return value;
  }

  // Convert the number to a string and split it into integer and decimal parts
  const parts = value.toFixed(0).toString().split('.');

  // Add . as thousands separators to the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Join the parts back together
  return parts.join('.');
};

const militaryToAmPm = (value) => {
  if (!value) return '';

  const [hour, minute] = value.split(':').map(Number);
  let period = 'AM';
  let formattedHour = hour;

  if (hour === 0) {
    formattedHour = 12;
  } else if (hour === 12) {
    period = 'PM';
  } else if (hour > 12) {
    formattedHour = hour - 12;
    period = 'PM';
  }

  formattedHour = formattedHour.toString().padStart(2, '0');
  const formattedMinute = minute.toString().padStart(2, '0');

  return `${formattedHour}:${formattedMinute} ${period}`;
};

export { numberFormatNoDecimal, militaryToAmPm };
