let baseState = {
  filters: {
    date: [],
    sortFilter: {
      options: [
        {
          text: 'Fecha de creación',
          value: 'created_at'
        },
        {
          text: 'ID',
          value: 'unique_id'
        }
      ],
      optionValue: 'created_at',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: -1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Teléfono',
          value: 'phone'
        },
        {
          text: 'Correo electrónico',
          value: 'email'
        }
      ],
      optionValue: ''
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  }
};

const state = baseState;

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  resetFilters({ commit }) {
    commit('SET_FILTERS', baseState);
  }
};

const getters = {
  filters: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
