export default [
  {
    path: '/polls',
    name: 'polls',
    meta: {
      requiresAuth: true,
      permission: 'polls',
      title: 'Encuestas',
      module: 'polls',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-poll'
    },
    component: () =>
      import(/* webpackChunkName: "polls" */ '@/views/polls/PollsLayout.vue'),
    children: [
      {
        path: '',
        name: 'polls-index',
        meta: {
          permission: 'polls',
          requiresAuth: true,
          layout: 'admin',
          title: 'Lista de encuestas',
          module: 'polls',
          icon: 'mdi-view-list',
          adminLayoutNav: true
        },
        component: () =>
          import(
            /* webpackChunkName: "polls-index" */ '@/views/polls/PollsIndex.vue'
          )
      },
      {
        path: 'new',
        name: 'polls-new',
        meta: {
          permission: 'polls/new',
          requiresAuth: true,
          layout: 'admin',
          title: 'Nueva encuesta',
          module: 'polls',
          icon: 'mdi-plus',
          adminLayoutNav: true
        },
        component: () =>
          import(
            /* webpackChunkName: "polls-new" */ '@/views/polls/PollsNewShow.vue'
          )
      },
      {
        path: ':id',
        name: 'polls-show',
        meta: {
          requiresAuth: true,
          permission: 'polls',
          layout: 'admin',
          title: 'Detalle de encuesta',
          module: 'polls',
          adminLayoutNav: false
        },
        component: () =>
          import(
            /* webpackChunkName: "polls-show" */ '@/views/polls/PollsNewShow.vue'
          )
      }
    ]
  }
];
