

export default [
  {
    path: '/surgeH3',
    name: 'surgeH3',
    meta: {
      requiresAuth: true,
      permission: 'surge_h3',
      title: 'Surge H3',
      module: 'surge',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-hexagon',
      isBaseLayout: true
    },
    component: () =>
      import(
         '@/views/surge/SurgeLayout.vue'
      ),
    children: [
      {
        path: '',
        name: 'surge-h3-list',
        meta: {
          adminLayoutNav: true,
          requiresAuth: true,
          permission: 'surge_h3',
          layout: 'admin',
          title: 'Listado por zona',
          icon: 'mdi-view-list'
        },
        component: () =>
          import(
             '@/views/surge/SurgeList.vue'
          )
      },
      {
        path: 'map',
        name: 'surge-h3-map',
        meta: {
          adminLayoutNav: true,
          requiresAuth: true,
          permission: 'surge_h3',
          layout: 'admin',
          title: 'Mapa de calor',
          icon: 'mdi-map'
        },
        component: () => import('@/views/surge/surgeMap.vue')
      },{
        path: 'config',
        name: 'surge-h3-config',
        meta: {
          adminLayoutNav: true,
          requiresAuth: true,
          permission: 'surge_h3',
          layout: 'admin',
          title: 'Hexágonos H3',
          icon: 'mdi-cogs'
        },
        component: () => import('@/views/surge/SurgeConfig.vue')
      }
    ]
  }
];
