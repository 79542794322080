export default [
  {
    path: '/admin',
    name: 'admin',
    component: () =>
      import(
        /* webpackChunkName: "Admin" */ '@/views/admin-page/AdminPage.vue'
      ),
    meta: {
      requiresAuth: true,
      permission: null,
      layout: 'admin',
      notBreadCrumb: true,
      excludedAdminLayoutNav: true
    }
  }
];
