const SCHEDULED_TASK_TYPES = {
  UPLOAD_PAYMENTS: 1
};
const SCHEDULED_TASK_STATUS = {
  PENDING: 1,
  PROCESSING: 2,
  COMPLETED: 3,
  CANCELLED: 4,
  FAILED: 5,
  REVERSED: 6
};

export { SCHEDULED_TASK_TYPES, SCHEDULED_TASK_STATUS };
