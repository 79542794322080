export default [
  {
    path: '/trips',
    name: 'trips',
    meta: {
      requiresAuth: true,
      permission: 'trips',
      title: 'Viajes',
      module: 'trips',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-navigation-variant'
    },
    component: () =>
      import(/* webpackChunkName: "trips" */ '@/views/trips/TripLayout.vue'),
    children: [
      {
        path: '',
        name: 'trips-history',
        meta: {
          adminLayoutNav: true,
          permission: 'trips-history',
          requiresAuth: true,
          layout: 'admin',
          title: 'Viajes',
          module: 'trips-history',
          icon: 'mdi-navigation-variant'
          // socket: true,
          // socketValueKey: "userAuth.userAuthDocumentCount.pending",
        },
        component: () =>
          import(/* webpackChunkName: "trips" */ '@/views/trips/Trip.vue')
      },
      {
        path: 'history',
        name: 'trips-history',
        meta: {
          adminLayoutNav: true,
          permission: 'trips-history',
          requiresAuth: true,
          layout: 'admin',
          title: 'Viajes histórico',
          module: 'trips-history',
          icon: 'mdi-navigation-variant'
          // socket: true,
          // socketValueKey: "userAuth.userAuthDocumentCount.pending",
        },
        component: () =>
          import(
            /* webpackChunkName: "trips-history" */ '@/views/trips/TripHistory.vue'
          )
      }
    ]
  }
];
