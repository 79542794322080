// store/modules/auth.js

const state = {
  filters: {
    vehicleTypes: {
      value: '',
      options: [],
      select: 0
    },
    sortFilter: {
      options: [
        {
          text: 'Última actualización',
          value: 'updated_at'
        },
        {
          text: 'Marca',
          value: 'brand.name'
        }
      ],
      optionValue: 'updated_at',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: -1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        }
      ],
      optionValue: ''
    },
    brandsAutoComplete: {
      value: [],
      options: [],
      selectAll: false
    },
    modelsAutoComplete: {
      value: [],
      options: [],
      selectAll: false
    },
    yearsAutoComplete: {
      initialOptions: [],
      value: [],
      options: [],
      selectAll: false
    },
    principalServiceAutoComplete: {
      value: [],
      options: [],
      selectAll: false
    },
    secondaryServicesAutoComplete: {
      value: [],
      options: [],
      selectAll: false
    },
    countriesAutoComplete: {
      value: [],
      options: [],
      selectAll: false
    },
    citiesAutoComplete: {
      value: [],
      options: [],
      selectAll: false
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  }
};

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  }
};

const getters = {
  filters: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
