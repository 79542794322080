<template>
  <svg
    :width="isHovered || !collapsedDrawer ? 100 : 50"
    :height="isHovered || !collapsedDrawer ? 33 : 33"
    :viewBox="isHovered || !collapsedDrawer ? '0 0 361 118' : '0 0 60 118'"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- i -->
    <path
      d="M72.273 8.25717C72.2662 3.96275 75.7714 0.453806 80.1864 0.446757C84.4733 0.439912 87.8617 3.94538 87.8685 8.23227C87.8756 12.6548 84.4984 16.1635 80.2115 16.1703C75.7965 16.1774 72.2801 12.6797 72.273 8.25717ZM86.834 91.6739L73.7096 91.6949L73.6038 25.4101L86.7357 25.3892L86.8415 91.6739L86.834 91.6739Z"
      :fill="'#ffffff'"
      v-show="isHovered || !collapsedDrawer"
    />
    <!-- d -->
    <path
      d="M155.588 91.564L155.575 83.2464C149.602 89.3661 141.291 93.1464 132.061 93.1611C112.435 93.1925 97.0712 77.7495 97.0407 58.6431C97.01 39.4087 112.453 23.9165 131.951 23.8854C141.052 23.8709 149.368 27.4965 155.36 33.5971L155.307 0.590382L168.431 0.569427L168.577 91.5433L155.588 91.564ZM132.881 35.5843C120.277 35.6045 110.152 45.7615 110.173 58.6297C110.193 71.3623 120.35 81.615 132.955 81.5949C145.559 81.5747 155.684 71.2896 155.663 58.5571C155.643 45.6889 145.486 35.5642 132.881 35.5843Z"
      :fill="'#ffffff'"
      v-show="isHovered || !collapsedDrawer"
    />
    <!-- e -->
    <path
      d="M212.793 23.8922C231.899 23.8617 245.959 38.5231 245.99 58.285L245.997 62.5719L192.061 62.658C193.894 73.4439 202.88 81.4835 214.053 81.4656C221.722 81.4534 228.212 78.3239 233.143 71.686L242.64 78.6926C236.024 87.5407 226.156 92.883 214.071 92.9023C194.188 92.9341 178.825 78.1391 178.794 58.3847C178.764 39.6701 193.43 23.9231 212.793 23.8922ZM192.3 52.253L232.721 52.1885C230.497 42.0587 222.297 35.3062 212.547 35.3218C202.806 35.3448 194.627 42.116 192.3 52.253Z"
      :fill="'#ffffff'"
      v-show="isHovered || !collapsedDrawer"
    />
    <!-- r -->
    <path
      d="M284.166 36.7743C275.585 36.788 269.357 43.428 269.373 53.6969L269.434 91.3898L256.309 91.4108L256.203 25.1185L269.2 25.0978L269.213 33.2873C272.451 27.9556 277.78 24.5642 285.058 24.5526L289.609 24.5454L289.628 36.7581L284.166 36.7668L284.166 36.7743Z"
      :fill="'#ffffff'"
      v-show="isHovered || !collapsedDrawer"
    />
    <!-- y -->
    <path
      d="M320.25 90.0052L292.587 25.0604L306.751 25.0378L326.976 74.1353L346.395 24.9745L360.039 24.9527L328.454 103.373C324.311 113.256 319.119 117.424 309.37 117.439L300.66 117.453L300.642 106.272L306.753 106.263C311.695 106.255 314.547 104.299 316.618 99.0971L320.25 90.0052Z"
      :fill="'#ffffff'"
      v-show="isHovered || !collapsedDrawer"
    />
    <!-- no se -->
    <path
      d="M0.145696 91.812L0.000427246 0.830658L33.9263 0.776489C49.3938 0.751792 61.2409 12.4333 61.2648 27.3809C61.283 38.8176 53.8924 48.4504 43.1092 51.971L66.566 91.706L51.3547 91.7303L13.7295 53.9843L13.7899 91.7902L0.145696 91.812ZM13.6637 12.773L13.7102 41.8846L32.1687 41.8551C41.2698 41.8406 47.4978 35.4568 47.485 27.4029C47.4719 19.2134 41.2233 12.729 32.1222 12.7435L13.6637 12.773V12.773Z"
      :fill="'#ffffff'"
      v-show="isHovered || !collapsedDrawer"
    />
    <!-- r -->
    <path
      d="M0.145696 91.812L0.000427246 0.830658L33.9263 0.776489C49.3938 0.751792 61.2409 12.4333 61.2648 27.3809C61.283 38.8176 53.8924 48.4504 43.1092 51.971L66.566 91.706L51.3547 91.7303L13.7295 53.9843L13.7899 91.7902L0.145696 91.812ZM13.6637 12.773L13.7102 41.8846L32.1687 41.8551C41.2698 41.8406 47.4978 35.4568 47.485 27.4029C47.4719 19.2134 41.2233 12.729 32.1222 12.7435L13.6637 12.773V12.773Z"
      :fill="'#ffffff'"
    />
    <!-- traingulo -->
    <path
      d="M18.3669 65.1199L18.4095 91.783L45.0801 91.7404L18.3669 65.1199Z"
      fill="#3BD4AE"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      isHovered: {
        type: Boolean,
        default: false
      },
      collapsedDrawer: {
        type: Boolean,
        default: false
      }
    }
  };
</script>

<style scoped>
  /* Add scoped styles if needed */
</style>
