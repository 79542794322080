export default [
  {
    path: '/home',
    name: 'home',
    meta: {
      requiresAuth: true,
      permission: null,
      title: 'Home',
      module: 'home',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-home'
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/home/HomeLayout.vue'),
    children: [
      {
        path: 'services',
        name: 'services',
        meta: {
          requiresAuth: true,
          permission: 'home/services',
          title: 'Servicios',
          module: 'home',
          layout: 'admin',
          adminLayoutNav: true,
          icon: 'mdi-taxi'
        },
        component: () =>
          import(
            /* webpackChunkName: "home-services" */ '@/views/home/HomeServiceLayout.vue'
          ),
        children: [
          {
            path: '',
            name: 'home-service-index',
            meta: {
              requiresAuth: true,
              permission: 'home/services',
              title: 'Servicios',
              module: 'home',
              layout: 'admin',
              adminLayoutNav: false
            },
            component: () =>
              import(
                /* webpackChunkName: "home-service-index" */ '@/views/home/HomeServiceIndex.vue'
              )
          },
          {
            path: ':id',
            name: 'service-edit',
            meta: {
              requiresAuth: true,
              permission: 'home/services',
              title: 'Editar el servicio',
              module: 'home',
              layout: 'admin',
              adminLayoutNav: false
            },
            component: () =>
              import(
                /* webpackChunkName: "home-service-edit" */ '@/views/home/HomeServiceEdit.vue'
              )
          }
        ]
      },
      {
        path: 'marketing',
        name: 'marketing',
        meta: {
          requiresAuth: true,
          permission: 'home',
          title: 'Publicidad',
          module: 'home',
          permission: 'home/marketing',
          layout: 'admin',
          adminLayoutNav: true,
          icon: 'mdi-bullseye'
        },
        component: () =>
          import(
            /* webpackChunkName: "home-advertising" */ '@/views/home/HomeAdvertisingLayout.vue'
          ),
        children: [
          {
            path: '',
            name: 'home-advertising-index',
            meta: {
              requiresAuth: true,
              permission: 'home/marketing',
              title: 'Publicidades',
              module: 'home',
              layout: 'admin',
              adminLayoutNav: false
            },
            component: () =>
              import(
                /* webpackChunkName: "home-service-index" */ '@/views/home/HomeAdvertisingIndex.vue'
              )
          },
          {
            path: 'new',
            name: 'marketing-new',
            meta: {
              permission: 'home/marketing',
              requiresAuth: true,
              layout: 'admin',
              title: 'Nuevo Banner',
              module: 'home',
              icon: 'mdi-plus',
              adminLayoutNav: false
            },
            component: () =>
              import(
                /* webpackChunkName: "home-service-index" */ '@/views/home/HomeAdvertisingNewShow.vue'
              )
          },
          {
            path: ':id',
            name: 'marketing-show',
            meta: {
              requiresAuth: true,
              permission: 'home/marketing',
              layout: 'admin',
              title: 'Detalle de banner',
              module: 'home',
              adminLayoutNav: false
            },
            component: () =>
              import(
                /* webpackChunkName: "home-service-index" */ '@/views/home/HomeAdvertisingNewShow.vue'
              )
          }
        ]
      }
    ]
  }
];
