import Vue from 'vue';
import App from './App.vue';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import '@/plugins/vee-validate';
import './assets/scss/global.scss';

Vue.config.productionTip = false;

import axiosPlugin from './plugins/axios-plugin';

Vue.use(axiosPlugin);

import VuetifyDialog from 'vuetify-dialog';
import 'vuetify-dialog/dist/vuetify-dialog.css';

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});

Vue.use(VueMoment, {
  moment
});
moment.tz.setDefault('UTC');

import * as filters from './filters';

// Register all filters
Object.keys(filters).forEach((filterName) => {
  Vue.filter(filterName, filters[filterName]);
});

import VueSocketIO from 'vue-socket.io';

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: process.env.VUE_APP_SOCKET_URL
  })
);

import VueCurrencyFilter from 'vue-currency-filter';

Vue.use(VueCurrencyFilter, [
  {
    name: 'usd',
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined
  },
  {
    name: 'bs',
    symbol: 'Bs.',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined
  },
  {
    name: 'currency',
    symbol: '',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined
  }
]);

import { LMap, LTileLayer, LMarker, LPolyline, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-polyline', LPolyline);
Vue.component('l-popup', LPopup);

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
