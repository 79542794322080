// store/modules/auth.js

const state = {
  user: {
    isLoggedIn: false,
    token: null, // Set this to an appropriate initial value
    userDetails: {}
  }
  // user: {
  //   isLoggedIn: true,
  //   token: '1346', // Set this to an appropriate initial value
  //   userDetails: {
  //     username: 'Damg',
  //     email: 'email@email.com'
  //   },
  // },
};

const mutations = {
  SET_USER(state, user) {
    state.user = { ...state.user, ...user };
  }
};

const actions = {
  setUser({ commit }, user) {
    commit('SET_USER', user);
  }
};

const getters = {
  user: (state) => state.user
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
