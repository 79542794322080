export default [
  {
    path: '/providers',
    name: 'providers',
    meta: {
      requiresAuth: true,
      permission: 'providers/providers-list',
      title: 'Conductores',
      module: 'providers',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-car-select',
      isBaseLayout: true
    },
    component: () =>
      import(
        /* webpackChunkName: "api-partners" */ '@/views/providers/ProvidersLayout.vue'
      ),
    children: [
      {
        path: '',
        name: 'providers-list',
        meta: {
          adminLayoutNav: true,
          permission: 'providers/providers-list',
          requiresAuth: true,
          layout: 'admin',
          title: 'Lista de conductores',
          module: 'providers',
          icon: 'mdi-view-list',
          providerStatus: 'all',
          allowedTotalDocumentsForIndexPages: true
        },
        component: () =>
          import(
            /* webpackChunkName: "shipment-partners" */ '@/views/providers/ProvidersList.vue'
          )
      }
      // {
      //   path: "approved",
      //   name: "providers-list-approved",
      //   meta: {
      //     adminLayoutNav: true,
      //     // permission: "providers/providers-list-approved",
      //     requiresAuth: true,
      //     layout: "admin",
      //     title: "Aprobados",
      //     module: "providers",
      //     icon: "mdi-view-list",
      //     providerStatus: "approved",
      //   },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "shipment-partners" */ "../views/providers/ProvidersList.vue"
      //     ),
      // },
    ]
  }
];
