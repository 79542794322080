export default [
  {
    path: '/administrative-tasks',
    name: 'administrative-tasks',
    redirect: {
      name: 'export-tasks-index'
    },
    meta: {
      requiresAuth: true,
      permission: 'administrative-tasks',
      title: 'Tareas administrativas',
      module: 'administrative-tasks',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-dots-horizontal'
    },
    component: () =>
      import('@/views/administrative-tasks/AdministrativeTasksLayout.vue'),
    children: [
      {
        path: 'export-tasks',
        name: 'export-tasks-index',
        meta: {
          adminLayoutNav: true,
          permission: 'RideryOffice_ExportTasks/list',
          requiresAuth: true,
          layout: 'admin',
          title: 'Tareas de exportación',
          module: 'export-tasks',
          icon: 'mdi-export-variant'
        },
        component: () => import('@/views/export-tasks/ExportTaskIndex.vue')
      },
      {
        path: 'scheduled-tasks',
        name: 'scheduled-tasks-index',
        meta: {
          adminLayoutNav: true,
          permission: 'RideryOffice_ScheduledTasks/list',
          requiresAuth: true,
          layout: 'admin',
          title: 'Tareas programadas',
          module: 'export-tasks',
          icon: 'mdi-clock-outline'
        },
        component: () =>
          import('@/views/scheduled-tasks/ScheduledTaskIndex.vue')
      },
      {
        path: 'bulk-payment-drivers',
        name: 'bulk-payment-drivers-index',
        meta: {
          adminLayoutNav: true,
          permission: 'RideryOffice_DriverPayments/list',
          requiresAuth: true,
          layout: 'admin',
          title: 'Pago a conductores',
          module: 'bulk-payment-drivers',
          icon: 'mdi-currency-usd'
        },
        component: () =>
          import('@/views/bulk-payment-drivers/BulkPaymentDriversIndex.vue')
      },
      {
        path: 'bulk-payment-drivers/create',
        name: 'bulk-payment-drivers-create',
        meta: {
          adminLayoutNav: false,
          permission: 'RideryOffice_DriverPayments/create',
          requiresAuth: true,
          layout: 'admin',
          title: 'Crear nuevo pago a conductores',
          module: 'bulk-payment-drivers',
          icon: 'mdi-currency-usd'
        },
        component: () =>
          import('@/views/bulk-payment-drivers/BulkPaymentDriversCreate.vue')
      },
      {
        path: 'bulk-payment-drivers/:unique_id/records',
        name: 'payment-driver-records-index',
        meta: {
          adminLayoutNav: false,
          permission: 'RideryOffice_DriverPayments/list',
          requiresAuth: true,
          layout: 'admin',
          title: 'Registros de pago a conductores',
          module: 'bulk-payment-drivers',
          icon: 'mdi-currency-usd'
        },
        component: () =>
          import('@/views/payment-driver-records/PaymentDriverRecordsIndex.vue')
      }
    ]
  }
];
