import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/src/locale/es.ts';
import en from 'vuetify/src/locale/en.ts';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      options: {
        customProperties: true,
        variations: false
      },
      light: {
        primary: '#000421',
        secondary: '#3BD4AE',
        error: '#DE2568',
        success: '#3BD4AE'
      },
      dark: {
        primary: '#3BD4AE',
        secondary: '#000020',
        error: '#DE2568',
        success: '#3BD4AE'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  }
});
