// store/modules/auth.js

const state = {
  filters: {
    date: [],
    sortFilter: {
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Creado',
          value: 'created_at'
        }
      ],
      optionValue: 'unique_id',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: -1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Título',
          value: 'name'
        }
      ],
      optionValue: ''
    },
    citiesAutoComplete: {
      value: [],
      options: [],
      selectAll: false
    },
    fleetAutoComplete: {
      value: [],
      options: [],
      selectAll: false
    },
    accesoryAutoComplete: {
      value: [],
      options: [],
      selectAll: false
    },
    status: {
      value: undefined,
      options: [
        {
          text: 'Activo',
          value: 1
        },
        {
          text: 'Finalizado',
          value: 2
        },
        {
          text: 'Inactivo',
          value: 0
        }
      ]
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  }
};

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, user) {
    commit('SET_FILTERS', user);
  }
};

const getters = {
  user: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
