export function toLowerCase(string) {
  return string.toLowerCase();
}

export function capitalizeFirstLetter(string) {
  return string ? string[0].toUpperCase() + string.slice(1) : '';
}

export async function getYearList(min, max, present = false) {
  const yearsList = [];
  for (var i = min; i <= max; i++) {
    yearsList.push(i);
  }
  if (present) {
    yearsList.push('Presente');
  }
  return yearsList;
}

export function doRangesOverlap(rangeA, rangeB) {
  const { start: startA, end: endA } = rangeA;
  const { start: startB, end: endB } = rangeB;

  return startA <= endB && startB <= endA;
}

export function generateUniqueId() {
  const now = new Date();
  return (
    now.getFullYear() +
    ('0' + (now.getMonth() + 1)).slice(-2) +
    ('0' + now.getDate()).slice(-2) +
    ('0' + now.getHours()).slice(-2) +
    ('0' + now.getMinutes()).slice(-2) +
    ('0' + now.getSeconds()).slice(-2) +
    now.getMilliseconds()
  );
}

/**
 * Validates if a given string is a valid MongoDB ObjectId.
 * @param {string} id - The string to validate.
 * @return {boolean} - Returns true if the string is a valid ObjectId, false otherwise.
 */
export function isValidMongoId(id) {
  let isValid = typeof id === 'string' && /^[0-9a-fA-F]{24}$/.test(id);

  return isValid ? id : false;
}
