export default [
  {
    path: '/api-partners',
    name: 'api-partners',
    meta: {
      title: 'Api Partners',
      module: 'api-partners',
      layout: 'admin',
      adminLayoutNav: true,
      icon: 'mdi-api',
      isBaseLayout: true
    },
    component: () =>
      import(
        /* webpackChunkName: "api-partners" */ '@/views/api-partners/ApiPartnersLayout.vue'
      ),
    children: [
      {
        path: 'trips',
        name: 'api-partners-trips',
        meta: {
          adminLayoutNav: true,
          requiresAuth: true,
          layout: 'admin',
          title: 'Envíos',
          module: 'api-partners',
          icon: 'mdi-car-hatchback',
          allowedTotalDocumentsForIndexPages: true
        },
        component: () =>
          import(
            /* webpackChunkName: "api-partners-trip-histories" */ '@/views/api-partners/ApiPartnersTrips.vue'
          )
      },
      {
        path: 'trip-histories',
        name: 'api-partners-trip-histories',
        meta: {
          adminLayoutNav: true,
          requiresAuth: true,
          layout: 'admin',
          title: 'Historial de envíos',
          module: 'api-partners',
          icon: 'mdi-car-multiple',
          allowedTotalDocumentsForIndexPages: true
        },
        component: () =>
          import(
            /* webpackChunkName: "api-partners-trip-histories" */ '@/views/api-partners/ApiPartnersTripHistories.vue'
          )
      },
      {
        path: '',
        name: 'shipment-partners',
        meta: {
          adminLayoutNav: true,
          permission: 'api-partners/shipment-partners',
          requiresAuth: true,
          layout: 'admin',
          title: 'Partners Envíos',
          module: 'api-partners',
          icon: 'mdi-package-variant-closed'
        },
        component: () =>
          import(
            /* webpackChunkName: "shipment-partners" */ '@/views/api-partners/ShipmentPartners.vue'
          )
      },
      {
        path: 'config',
        name: 'api-partners-config',
        meta: {
          adminLayoutNav: true,
          permission: 'api-partners/config',
          requiresAuth: true,
          layout: 'admin',
          title: 'Configuración',
          module: 'api-partners',
          icon: 'mdi-cogs'
        },
        component: () =>
          import(
            /* webpackChunkName: "api-partners-config" */ '@/views/api-partners/ApiPartnersConfig.vue'
          )
      },
      {
        path: ':id', // Dynamic segment for the partner ID
        name: 'api-partner-details',
        meta: {
          adminLayoutNav: false,
          permission: 'api-partners/shipment-partners/edit',
          requiresAuth: true,
          layout: 'admin',
          title: 'Detalles de api partner',
          module: 'api-partners'
        },
        component: () =>
          import(
            /* webpackChunkName: "api-partners" */ '@/views/api-partners/ApiPartnersLayout.vue'
          ),
        children: [
          {
            path: '',
            name: 'api-partner-edit',
            meta: {
              requiresAuth: true,
              permission: 'api-partners/shipment-partners/edit',
              title: 'Editar api partner',
              module: 'api-partners',
              layout: 'admin',
              adminLayoutNav: true,
              icon: 'mdi-api'
            },
            component: () =>
              import(
                /* webpackChunkName: "api-partners-edit" */ '@/views/api-partners/ApiPartnersEdit.vue'
              )
          },
          {
            path: 'price-city/new',
            name: 'price-city-new',
            meta: {
              adminLayoutNav: true,
              permission: 'api-partners/shipment-partners/price-city/new',
              requiresAuth: true,
              layout: 'admin',
              title: 'Agregar tarifa por ciudad',
              module: 'api-partners'
            },
            component: () =>
              import(
                /* webpackChunkName: "price-city-new" */ '@/views/api-partners/ApiPartnersPriceCityNewEdit.vue'
              )
          },
          {
            path: 'price-city/:id2',
            name: 'price-city-edit',
            meta: {
              adminLayoutNav: true,
              permission: 'api-partners/shipment-partners/price-city/new',
              requiresAuth: true,
              layout: 'admin',
              title: 'Editar tarifa por ciudad',
              module: 'api-partners'
            },
            component: () =>
              import(
                /* webpackChunkName: "price-city-new" */ '@/views/api-partners/ApiPartnersPriceCityNewEdit.vue'
              )
          }
        ]
      }
    ]
  }
];
