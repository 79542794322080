// store/modules/auth.js

const state = {
  filters: {
    sortFilter: {
      options: [
        {
          text: 'Modo',
          value: 'mode'
        },
        {
          text: 'Creado',
          value: 'created_at'
        }
      ],
      optionValue: 'mode',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: -1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'Metodo de pago',
          value: 'name'
        },
        {
          text: 'Modo',
          value: 'mode'
        }
      ],
      optionValue: ''
    },
    pagination: {
      page: 1,
      pages: 1,
      limit: 10
    },
    expandTable: false
  }
};

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filter) {
    commit('SET_FILTERS', filter);
  }
};

const getters = {
  filter: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
