const state = {
  filters: {
    sortFilter: {
      options: [
        {
          text: 'Fecha de creación',
          value: 'created_at'
        },
        {
          text: 'ID',
          value: 'unique_id'
        },
        {
          text: 'Nombre',
          value: 'name'
        }
      ],
      optionValue: 'created_at',
      sortModes: [
        {
          text: 'Ascendente',
          value: 1
        },
        {
          text: 'Descendente',
          value: -1
        }
      ],
      sortModeValue: -1
    },
    searchFilter: {
      searchValue: '',
      options: [
        {
          text: 'Nombre',
          value: 'fullName'
        }
      ],
      optionValue: ''
    }
  }
};

const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  }
};

const actions = {
  setFilters({ commit }, filters) {
    commit('SET_FILTERS', filters);
  },
  resetFilters({ commit }, payload = 1) {
    const originalState = {
      filters: {
        sortFilter: {
          options: [
            {
              text: 'Fecha de creación',
              value: 'created_at'
            },
            {
              text: 'ID',
              value: 'unique_id'
            }
          ],
          optionValue: 'created_at',
          sortModes: [
            {
              text: 'Ascendente',
              value: 1
            },
            {
              text: 'Descendente',
              value: -1
            }
          ],
          sortModeValue: -1
        },
        searchFilter: {
          searchValue: '',
          options: [
            {
              text: 'Nombre',
              value: 'fullName'
            }
          ],
          optionValue: ''
        }
      }
    };

    commit('SET_FILTERS', originalState);
  }
};

const getters = {
  user: (state) => state.filters
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
